/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import HoursTransformer from "../../../themes/answers-hitchhiker-theme/static/js/hours/transformer";

//show holiday hours if there are any in the upcoming week
export function holidayHours(profile) {
  if (!profile.hours) {
    return null;
  } else {
    let holidays = ``;
    const formattedHours = HoursTransformer._formatHoursForAnswers(profile.hours, profile.timeZoneUtcOffset);
    for (const day of formattedHours) {
      if (day.dailyHolidayHours && day.dailyHolidayHours.date) {
        let holidayHours = day.dailyHolidayHours;
        // Need to restructure date string to avoid Javascript 1 day off parse curiosity
        // Explanation here: https://stackoverflow.com/a/31732581
        let holidayDate = new Date(holidayHours.date.replace(/-/g, '\/').replace(/T.+/, ''));
        if (holidayHours.isClosed) {
          //closed on the holiday
          if (holidays.length > 0) {
            holidays += ', '
          }
          holidays += `<span class="HoursInterval-day">${holidayDate.toLocaleDateString('en-US')}</span> - Closed`;
        } else if (holidayHours.intervals && holidayHours.intervals.length > 0) {
          //open on the holiday
          //let startTime = this._getTimeString(holidayHours.intervals[0].start);
          let startTime = this._localizer.getLocalizedTime(hoursToday.nextTime);
          let endTime = this._getTimeString(holidayHours.intervals[0].end);
          if (holidays.length > 0) {
            holidays += ', '
          }
          holidays += `<span class="HoursInterval-day">${holidayDate.toLocaleDateString('en-US')}</span> - ${startTime}-${endTime}`;
        }
      }
    }
    if (holidays != '') {
      holidays = `<span class="Hours-holidayHours">Holiday Hours: </span>` + holidays;
    }
    return holidays;
  }
}

export function weeklyHours(profile) {
  if (!profile.hours) {
		return null;
	} else {
		const formattedHours = HoursTransformer._formatHoursForAnswers(profile.hours, profile.timeZoneUtcOffset);
		let weeklyHours = '<ul>';
    let today = new Date().getDay();
    let todayName = HoursTransformer._intToDay(today);

		for (const weekDay of formattedHours) {
      if (weekDay.isClosed) {
        if (weekDay.day == todayName) {
          weeklyHours += `<li><span class="HoursInterval-day" style="font-weight: bold;">${weekDay.day} - Closed</span></li>`;
        } else {
          weeklyHours += `<li><span class="HoursInterval-day">${weekDay.day} - Closed</span></li>`;
        }
      } else {
        if (weekDay.intervals[0].start == 0 && weekDay.intervals[0].end == 2359) {
          // Nothing needed in here since the openStatus will show location is open 24 hours a day
        } else {
          let openTime = weekDay.intervals[0].start;
          let closeTime = weekDay.intervals[0].end;

          if (weekDay.day == todayName) {
            weeklyHours += `<li><span class="HoursInterval-day" style="font-weight: bold;">${weekDay.day} - ${HoursTransformer._formatTime(openTime)}-${HoursTransformer._formatTime(closeTime)}</span></li>`;
          } else {
            weeklyHours += `<li><span class="HoursInterval-day">${weekDay.day} - ${HoursTransformer._formatTime(openTime)}-${HoursTransformer._formatTime(closeTime)}</span></li>`;
          }
        }
      }
    }
    weeklyHours += '</ul>';

    return weeklyHours;
	}
}
